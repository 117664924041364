import request from '@/utils/request'


// 查询常见问题列表
export function listProblem(query) {
  return request({
    url: '/problem/list',
    method: 'get',
    params: query
  })
}
export function listProblemType(query) {
  return request({
    url: '/problem/type/list',
    method: 'get',
    params: query
  })
}
// 查询常见问题分页
export function pageProblem(query) {
  return request({
    url: '/problem/page',
    method: 'get',
    params: query
  })
}

// 查询常见问题详细
export function getProblem(data) {
  return request({
    url: '/problem/detail',
    method: 'get',
    params: data
  })
}

// 新增常见问题
export function addProblem(data) {
  return request({
    url: '/problem/add',
    method: 'post',
    data: data
  })
}
// 常见问题状态修改
export function editProblemStatus(id, status) {
  const statusData = {
    id,
    status
  }
  return request({
    url: '/problem/status',
    method: 'post',
    data: statusData
  })
}
// 修改常见问题
export function updateProblem(data) {
  return request({
    url: '/problem/edit',
    method: 'post',
    data: data
  })
}

// 删除常见问题
export function delProblem(data) {
  return request({
    url: '/problem/delete',
    method: 'post',
    data: data
  })
}
